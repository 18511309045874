import React, { Component } from 'react'
import Layout from '../components/layout/layout'

import ServicesDetail from '../components/Services/ServicesDetail'

export default class BusinessStrategy extends Component {
  render() {
    const serviceImage = require('../assets/images/rawpixel-252130-unsplash.jpg')
    const majapa = require('../assets/images/majapa.png')
    const mark = require('../assets/images/mark.jpg')
    const sophie = require('../assets/images/sophie.jpg')
    const kevin = require('../assets/images/kevin.jpg')
    return (
      <Layout location={this.props.location}>
        <ServicesDetail
          service="Business Strategy"
          serviceCaption="GLOBAL STRATEGIES, INSIGHT-DRIVEN TRANSFORMATION"
          serviceHighlight={[
            'Business planning',
            'Business development',
            'Growth strategy and innovation',
          ]}
          serviceDocumentLink="https://drive.google.com/file/d/14ktdRLmYCbH8MVA9GaavZbV3i0SI6tk7/view?usp=sharing"
          serviceTopDescription="Many businesses operate away from their primary market, making market intelligence and successful entry in these markets quite crucial. In Sub-Saharan Africa, successful entry in new markets has always been a big challenge due to lack of access, intelligence and relevant growth strategy"
          serviceBottomDescription="Bora Growth Partners helps businesses overcome these challenges by offering a collaborative solution in developing and implementing their growth strategy, new Market Entry Intelligence and Market Progress Reporting."
          serviceImage={serviceImage}
          serviceSpecialists={[
            {
              name: 'Mohamed Majapa',
              title: 'Managing partner, Co-founder',
              linkedin: 'https://www.linkedin.com/in/mohamed-majapa-2a93a2b2/',
              twitter: 'https://twitter.com/Ti_Mj',
              image: majapa,
            },
            {
              name: 'Mark Schreiber',
              title: 'Senior consultant, business development',
              linkedin: 'https://za.linkedin.com/in/markaschreiber',
              twitter: 'https://twitter.com/MarkASchreiber',
              image: mark,
            },
            {
              name: 'Kevin Kageuka',
              title: 'Market Research Associate',
              linkedin:
                'https://www.linkedin.com/in/kevin-kageuka-0a849994/?ppe=1',
              twitter: 'https://twitter.com/kevinkageuka',
              image: kevin,
            },
          ]}
        />
      </Layout>
    )
  }
}
